import React from 'react';
import styled from '@material-ui/core/styles/styled';
import { useTranslation } from 'react-i18next';
import { TCustomTheme } from '../../const/theme';
import Group from '../common/Group';
import Button from '../common/Button';
import { ReactComponent as HeadIcon } from '../../assets/img/rules-logo.svg';
import Link from '@material-ui/core/Link/Link';

const GroupBody = styled('div')({
  textAlign: 'center'
});

const List = styled('ol')({
  listStyle: 'none',
  counterReset: 'circle-counter',
  paddingLeft: 40
});

const ListItem = styled('li')(({ theme }: { theme: TCustomTheme }) => ({
  margin: '0 0 15px 0',
  counterIncrement: 'circle-counter',
  position: 'relative',
  fontSize: 15,
  lineHeight: '25px',
  paddingTop: 2,
  '&:before': {
    content: 'counter(circle-counter)',
    fontSize: 15,
    color: 'white',
    position: 'absolute',
    left: -34,
    lineHeight: '24px',
    width: 24,
    height: 24,
    top: 0,
    background: theme.colors.darkGrey,
    borderRadius: '50%',
    textAlign: 'center'
  },
  [theme.breakpoints.up('md')]: {
    margin: '0 0 30px 0'
  }
}));

function RulesGroup() {
  const { t } = useTranslation();

  const items = [
    t('rule.one'),
    t('rule.two'),
    t('rule.three'),
    t('rule.four'),
    t('rule.five')
  ];

  return (
    <Group head={ t('rules') } icon={ <HeadIcon /> } >
      <List>
        { items.map((item, i) => (
          <ListItem key={ i }>{ item }</ListItem>
        )) }
      </List>
      <GroupBody>
        <Link href="https://sz.kz/tvoya-udacha3" underline="none" target="_blank" rel="noopener noreferrer">
          <Button variant="outlined" color="primary" disableElevation>{ t('allRules') }</Button>
        </Link>
      </GroupBody>
    </Group>
  );
}

export default RulesGroup;
