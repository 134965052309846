import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import styled from '@material-ui/core/styles/styled';
import { Trans, useTranslation } from 'react-i18next';
import { TCustomTheme } from '../../const/theme';
import { ReactComponent as LotteryLogo } from '../../assets/img/national-lottery.svg';

const StyledHead = styled(Typography)(({ theme }) => ({
  marginTop: 60,
  marginBottom: 30,
  [theme.breakpoints.up('md')]: {
    textAlign: 'center',
    marginBottom: 15
  }
}));

const Strong = styled('strong')(({ theme }) => ({
  fontWeight: 700,
  [theme.breakpoints.up('md')]: {
    fontWeight: 900
  }
}));

const Description = styled(Typography)(({ theme }) => ({
  marginBottom: 24,
  [theme.breakpoints.up('md')]: {
    textAlign: 'center',
    marginBottom: 40,
    color: theme.palette.primary.main
  }
}));

const LotteryGrid = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 40
}));

const LotteryDescription = styled('span')(({ theme }: { theme: TCustomTheme}) => ({
  color: theme.colors.grey,
  paddingLeft: 25,
  fontSize: 11,
  [theme.breakpoints.up('md')]: {
    color: theme.colors.darkGrey,
    fontWeight: 700,
    fontSize: 20,
    fontFamily: 'Tele2'
  }
}));

const StyledLotteryLogo = styled(LotteryLogo)(({ theme }) => ({
  width: '100%',
  height: 'auto',
  maxWidth: 150
}));

function MainDescription() {
  const { t } = useTranslation();

  return (
    <>
      <StyledHead variant="h4" color="secondary" >
        { t('lotteryYourLuck') }
      </StyledHead>
      <Description variant="h5">
        <Trans
          i18nKey="momentLottery"
          values={ { name: 'TELE2' } }
          components={ [<Strong key="1">name</Strong>] }
        />
      </Description>
      <Grid container justifyContent="center">
        <Grid item xs={ 12 } md={ 9 }>
          <LotteryGrid >
            <StyledLotteryLogo />
            <LotteryDescription>
              { t('developWith') }
            </LotteryDescription>
          </LotteryGrid>
        </Grid>
      </Grid>
    </>
  );
}

export default MainDescription;
