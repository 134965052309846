import React from 'react';
import Typography from '@material-ui/core/Typography';
import styled from '@material-ui/core/styles/styled';
import { TCustomTheme } from '../../const/theme';

const GroupWrapper = styled('div')(({ theme }: { theme: TCustomTheme }) => ({
  marginTop: 30,
  marginBottom: 30
}));

const GroupHead = styled('div')(({ theme }: { theme: TCustomTheme }) => ({
  borderRadius: 4,
  border: `1px solid ${theme.colors.lightGrey}`,
  backgroundColor: theme.colors.lightestGrey,
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  padding: 10,
  [theme.breakpoints.up('sm')]: {
    padding: 18
  }
}));

const GroupBody = styled('div')(({ theme }: { theme: TCustomTheme }) => ({
  borderRadius: '4px',
  border: `1px solid ${theme.colors.lightGrey}`,
  borderTop: `1px solid ${theme.colors.lightGrey}`,
  padding: '30px',
  [theme.breakpoints.up('sm')]: {
    padding: '15px 18px 30px 18px'
  }
}));

type TProps = React.PropsWithChildren & {
  head?: string;
  icon?: React.ReactNode;
};

const Group: React.FC<TProps> = ({ head, icon = null, children }) => (
  <GroupWrapper>
    {
      head
        ?
          <GroupHead style={ {
            padding: head && '22px 28px 15px 30px'
          } }>
            <div>
              <Typography variant="h2">
                { head }
              </Typography>
            </div>
            { icon }
          </GroupHead>
        : null
    }
    <GroupBody
      style={ {
        borderTop: head && 0,
        borderRadius: head && '0 0 4px 4px',
        padding: head && '30px 30px'
      } }>
      { children }
    </GroupBody>
  </GroupWrapper>
);

export default Group;
