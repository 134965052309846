import React, { FC } from 'react';
import styled from '@material-ui/core/styles/styled';
import MuiModal from '@material-ui/core/Modal/Modal';
import { useTranslation } from 'react-i18next';
import Button from '../common/Button';
import { ReactComponent as Cross } from '../../assets/img/cross.svg';

const HeadText = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontFamily: 'Tele2',
  fontSize: 28,
  fontWeight: 700,
  textTransform: 'uppercase'
}));

const StyledModal = styled(MuiModal)({
  overflowY: 'auto'
});

const ModalFooter = styled('div')({
  textAlign: 'center'
});

const ModalContent = styled('div')(({ theme }) => ({
  width: '100vw',
  minHeight: '100vh',
  backgroundColor: 'white',
  padding: 32,
  boxSizing: 'border-box',
  [theme.breakpoints.up('md')]: {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 640,
    minHeight: 'unset'
  }
}));

export const ModalHead = styled('div')({
  display: 'flex',
  justifyContent: 'space-between'
});

export const CloseCross = styled(Cross)({
  cursor: 'pointer'
});

export const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    paddingRight: 64,
    paddingLeft: 64
  }
}));

type Props = {
  open: boolean;
  handleClose: () => void;
  localeKey: string;
};

const Modal: FC<Props> = ({ open, handleClose, localeKey }) => {
  const { t } = useTranslation();
  return (
    <StyledModal
      open={ open }
      onClose={ handleClose }
    >
      <ModalContent>
        <ModalHead>
          <HeadText>{ t(`${localeKey}.head`) }</HeadText>
          <CloseCross onClick={ handleClose } />
        </ModalHead>
        <div
          dangerouslySetInnerHTML={ { __html: t(`${localeKey}.desc`, {
            price: '100',
            ussd: '88',
            phone: `<a href="${ 'tel:88000809351' }" style="color: #0098DC;">8 800 080 93 51</a>`,
            style: `
              margin-top: 30px;
              margin-bottom: 30px;
              font-size: 15px;
              line-height: 25px;
             `
          })} }
        />
        <ModalFooter>
          <StyledButton onClick={ handleClose } variant="outlined" color="primary" disableElevation>
            { t(`${localeKey}.button`) }
          </StyledButton>
        </ModalFooter>
      </ModalContent>
    </StyledModal>
  );
};

export default Modal;
