import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import { Trans, useTranslation } from 'react-i18next';
import { TCustomTheme } from '../../const/theme';
import Button from '../common/Button';
import { ReactComponent as Banner } from '../../assets/img/tele2-banner.svg';
import { styled } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container/Container';

const BannerBackground = styled('div')(({ theme }: { theme: TCustomTheme }) => ({
  backgroundColor: theme.colors.lightestGrey
}));

const BannerBackgroundPart2 = styled('div')(({ theme }: { theme: TCustomTheme }) => ({
  backgroundColor: 'white',
  [theme.breakpoints.up('md')]: {
    height: 0,
    backgroundColor: theme.colors.lightestGrey
  }
}));

const BannerWrapper = styled('div')(({ theme }: { theme: TCustomTheme }) => ({
  paddingTop: 20,
  paddingBottom: 20,
  [theme.breakpoints.up('md')]: {
    paddingTop: 44,
    paddingBottom: 44,
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridAutoRows: '230px',
    height: 500
  }
}));

const BannerHead = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none'
  }
}));

const ButtonBlock = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: 630,
    position: 'relative',
    top: -380
  }
}));

const ButtonBlockText = styled(Typography)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    marginBottom: 40,
    display: 'block'
  }
}));

const ButtonBlockTextMobile = styled(Typography)(({ theme }) => ({
  marginBottom: 16,
  marginTop: 16,
  [theme.breakpoints.up('md')]: {
    marginBottom: 40,
    display: 'none'
  }
}));

const StyledBanner = styled(Banner)(({ theme }) => ({
  width: '80%',
  height: 'auto',
  maxWidth: '486px',
  [theme.breakpoints.up('md')]: {
    width: '100%'
  }
}));

const BannerImgWrapper = styled('div')(({ theme }) => ({
  textAlign: 'right',
  [theme.breakpoints.up('md')]: {
    textAlign: 'unset',
    gridColumnStart: 2
  }
}));

const Strong = styled('strong')(({ theme }) => ({
  color: theme.palette.secondary.main
}));

type Props = {
  setOpenModalInfo: () => void;
  setOpenModalSubscribe: () => void;
};

const MainBanner: FC<Props> = ({ setOpenModalInfo, setOpenModalSubscribe }) => {
  const { t } = useTranslation();

  return (
    <>
      <BannerBackground>
        <Container>
          <BannerWrapper>
            <BannerHead variant="h1" color="secondary">
              { t('yourLuck') }
            </BannerHead>
            <BannerImgWrapper>
              <StyledBanner />
            </BannerImgWrapper>
          </BannerWrapper>
        </Container>
      </BannerBackground>
      <BannerBackgroundPart2>
        <Container>
          <ButtonBlock>
            <ButtonBlockText variant="h3">
              <Trans
                i18nKey="yourLuckLottery"
                components={ [ <Strong key="1">name</Strong> ] }
              />
              <br />
              { t('withPrizes') }
            </ButtonBlockText>
            <ButtonBlockTextMobile variant="h3">
              { t('lotteryWithPrizes') }
            </ButtonBlockTextMobile>
            <Button onClick={ setOpenModalInfo } fullWidth color="secondary" variant="contained" disableElevation >
              { t('participate') }
            </Button>
            <p/>
            <Button onClick={ setOpenModalSubscribe } fullWidth variant="outlined" color="primary" disableElevation >
              { t('subscribeOff') }
            </Button>
          </ButtonBlock>
        </Container>
      </BannerBackgroundPart2>
    </>
  );
};

export default MainBanner;
