import React from 'react';
import Typography from '@material-ui/core/Typography';
import styled from '@material-ui/core/styles/styled';
import { useTranslation } from 'react-i18next';
import { ReactComponent as HeadIcon } from '../../assets/img/prizes-logo.svg';
import { ReactComponent as TrophyIcon } from '../../assets/img/trophy.svg';
import { ReactComponent as MoneyIcon } from '../../assets/img/money-stack.svg';
import Group from '../common/Group';

const GroupFooter = styled('div')({
  display: 'flex'
});

const FooterText = styled('div')({
  paddingLeft: 10
});

const List = styled('ul')({
  listStyle: 'none',
  padding: 0
});

const StyledLi = styled('li')({
  display: 'flex',
  alignItems: 'center',
  paddingTop: 14,
  paddingBottom: 14
});

const ListText = styled('span')({
  paddingLeft: 10,
  fontSize: 15
});

const StyledMoneyIcon = styled(MoneyIcon)({
  width: 24,
  height: 24
});

const SuperPrizeText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    fontSize: 20
  }
}));

const ListItem: React.FC<React.PropsWithChildren> = ({ children }) => (
  <StyledLi>
    <StyledMoneyIcon />
    <ListText>
      { children }
    </ListText>
  </StyledLi>
);

function PrizesGroup() {
  const { t } = useTranslation();

  return (
    <Group head={ t('prizes') } icon={ <HeadIcon /> }>
      <Typography variant="h5" color="secondary" >
        { t('prizesFund') }:
      </Typography>
      <List>
        <ListItem>
          { t('prize.100') }
        </ListItem>
        <ListItem>
          { t('prize.200') }
        </ListItem>
        <ListItem>
          { t('prize.400') }
        </ListItem>
        <ListItem>
          { t('prize.1000') }
        </ListItem>
        <ListItem>
          { t('prize.2000') }
        </ListItem>
        <ListItem>
          { t('prize.4000') }
        </ListItem>
      </List>
      <GroupFooter>
        <TrophyIcon />
        <FooterText>
          <SuperPrizeText variant="h5" gutterBottom >
            { t('prize.super') }
          </SuperPrizeText>
          <Typography variant="h4" color="secondary">
            { t('prize.1000000') }
          </Typography>
        </FooterText>
      </GroupFooter>
    </Group>
  );
}

export default PrizesGroup;
