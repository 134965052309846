import React, { useCallback, useState } from 'react';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid/Grid';
import Typography from '@material-ui/core/Typography';

import Modal from '../modals/Modal';
import MainBanner from './MainBanner';
import MainDescription from './MainDescription';
import SubscriptionGroup from './SubscribtionGroup';
import PrizesGroup from './PrizesGroup';
import RulesGroup from './RulesGroup';
import Group from '../common/Group';

import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'react-use';

function MainPage() {
  const { t } = useTranslation();
  const [openModalInfo, setOpenModalInfo] = useState<boolean>(false);
  const [openModalSubscribe, setOpenModalSubscribe] = useState<boolean>(false);
  const { width } = useWindowSize();

  const toggleModalInfo = useCallback(() => {
    setOpenModalInfo(!openModalInfo);
  }, [openModalInfo]);
  const toggleModalSubscribe = useCallback(() => {
    setOpenModalSubscribe(!openModalSubscribe);
  }, [openModalSubscribe]);

  return (
    <>
      <MainBanner setOpenModalInfo={ toggleModalInfo } setOpenModalSubscribe={ toggleModalSubscribe }/>
      <Container>
        <MainDescription />
        <Grid container justifyContent="center">
          <Grid item xs={ 12 } md={ 9 }>
            <SubscriptionGroup setOpenModalInfo={ toggleModalInfo } setOpenModalSubscribe={ toggleModalSubscribe }/>
            <PrizesGroup />
            <RulesGroup />
            <Group>
              <Typography
                align={ 'center' }
                style={ {
                  padding: width > 600 ? '30px 42px 15px' : 0
                } }
                dangerouslySetInnerHTML={ {
                  __html: t('postscript', {
                    phone: `
                   <span style="display: inline-flex;">
                    <a href="${ 'tel:88000809351' }">
                      <b style="color: #0098DC">8 800 080 93 51</b>
                    </a>
                  </span>
                  `
                  })
                } }
              />
            </Group>
          </Grid>
        </Grid>
        <Modal localeKey={ 'modalInfo' } open={ openModalInfo } handleClose={ toggleModalInfo } />
        <Modal localeKey={ 'modalSubscribe' } open={ openModalSubscribe } handleClose={ toggleModalSubscribe } />
      </Container>
    </>
  );
}

export default MainPage;
