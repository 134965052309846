import React from 'react';
import styled from '@material-ui/core/styles/styled';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link/Link';
import { useTranslation } from 'react-i18next';
import { TCustomTheme } from '../../const/theme';

const StyledFooter = styled('footer')(({ theme }: { theme: TCustomTheme }) => ({
  backgroundColor: theme.colors.darkGrey,
  flex: '0 0 auto',
  marginTop: 10,
  [theme.breakpoints.up('md')]: {
    marginTop: 50
  }
}));

const FooterWrapper = styled('div')(({ theme }) => ({
  paddingTop: 24,
  paddingBottom: 24,
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 40,
    paddingBottom: 50
  }
}));

const CopyRight = styled('div')(({ theme }) => ({
  paddingTop: 20,
  [theme.breakpoints.up('md')]: {
    paddingTop: 0,
    width: '50%'
  }
}));

const CopyrightText = styled(Typography)(({ theme }: { theme: TCustomTheme }) => ({
  color: theme.colors.grey
}));

const LinkBlock = styled('div')(({ theme }: { theme: TCustomTheme }) => ({
  color: 'white',
  display: 'flex',
  flexDirection: 'column-reverse',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row'
  }
}));

const StyledLink = styled(Link)(({ theme }: { theme: TCustomTheme }) => ({
  color: 'white',
  display: 'block',
  marginTop: 15,
  [theme.breakpoints.up('md')]: {
    display: 'inline',
    paddingLeft: 13,
    '&:first-child': {
      paddingLeft: 0,
      paddingRight: 12,
      borderRight: '1px solid white'
    }
  }
}));

export function Footer() {
  const { t } = useTranslation();

  return (
    <StyledFooter>
      <Container>
        <FooterWrapper>
          <div>
            <CopyrightText variant="body2">
              { t('copyRight', { year: new Date().getFullYear() }) }
            </CopyrightText>
            <LinkBlock>
              <StyledLink href="mailto:lottery@cmid.kz" target="_blank" variant="body2" color="inherit">{ t('feedback') }</StyledLink>
              <StyledLink href="/oferta.pdf" target="_blank" variant="body2" color="inherit">{ t('userAgreement') }</StyledLink>
            </LinkBlock>
          </div>
          <CopyRight>
            <CopyrightText variant="body2">
              { t('copyRight2') }
            </CopyrightText>
          </CopyRight>
        </FooterWrapper>
      </Container>
    </StyledFooter>
  );
}
