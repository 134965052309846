import React from 'react';
import Container from '@material-ui/core/Container';
import styled from '@material-ui/core/styles/styled';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from '../../assets/img/logo.svg';
import { TCustomTheme } from '../../const/theme';
import Typography from '@material-ui/core/Typography';

const LangWrapper = styled('div')(({ theme }: { theme: TCustomTheme }) => ({
  backgroundColor: theme.colors.lightestGrey,
  paddingTop: 12,
  paddingBottom: 12
}));

const LangItems = styled('div')(({ theme }: { theme: TCustomTheme }) => ({
  textAlign: 'right',
  fontSize: 13,
  color: theme.colors.grey
}));

const TopBar = styled(
  'div'
)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center'
}));

const InfoItems = styled(
  'span'
)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '15px',
  lineHeight: '25px',
  color: '#1F2229',
  display: 'flex',
  alignItems: 'center',
  marginRight: 80,
  width: '100%',
  justifyContent: 'end',
  [theme.breakpoints.down('sm')]: {
    marginRight: 0,
    justifyContent: 'start'
  }
}));

const LangLabel = styled(
  'span'
)(({ theme, active }: any) => ({
  color: active ? theme.colors.grey : theme.colors.darkGrey,
  cursor: 'pointer',
  textTransform: 'uppercase'
}));

const LogoWrapper = styled('div')(({ theme }: { theme: TCustomTheme }) => ({
  paddingTop: 15,
  paddingBottom: 15
}));

const StyledLogo = styled(Logo)(({ theme }: { theme: TCustomTheme }) => ({
  width: 110,
  height: 'auto'
}));

export function Header() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng: string) => () => {
    i18n.changeLanguage(lng);
  };

  return (
    <header>
      <LangWrapper>
        <Container>
          <TopBar>
            <InfoItems>
              <span style={ { marginRight: 8 } }>
                { t('hotLine') }:
              </span>
              <a href={ 'tel:88000809351' }>
                <Typography color={ 'secondary' }>
                  <b>8 800 080 93 51</b>
                </Typography>
              </a>
            </InfoItems>
            <LangItems>
              <LangLabel role="button" onClick={ changeLanguage('ru') } active={ i18n.language !== 'kz' ? 'true' : '' }>
                РУС
              </LangLabel>
              &nbsp;|&nbsp;
              <LangLabel role="button" onClick={ changeLanguage('kz') } active={ i18n.language === 'kz' ? 'true' : '' }>
                ҚАЗ
              </LangLabel>
            </LangItems>
          </TopBar>
        </Container>
      </LangWrapper>
      <LogoWrapper>
        <Container>
          <StyledLogo />
        </Container>
      </LogoWrapper>
    </header>
  );
}
