import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import styled from '@material-ui/core/styles/styled';
import { useTranslation } from 'react-i18next';
import Group from '../common/Group';
import Button from '../common/Button';
import { ReactComponent as HeadIcon } from '../../assets/img/subscription-logo.svg';

const GroupFooter = styled('div')({
  paddingTop: 15,
  textAlign: 'center'
});

type Props = {
  setOpenModalInfo: () => void;
  setOpenModalSubscribe: () => void;
};

const SubscriptionGroup: FC<Props> = ({ setOpenModalInfo, setOpenModalSubscribe }) => {
  const { t } = useTranslation();

  return (
    <Group head={ t('everyDay') } icon={ <HeadIcon /> }>
      <Typography variant="body1">
        { t('subscription') }
      </Typography>
      <GroupFooter>
        <Button onClick={ setOpenModalInfo } fullWidth color="secondary" variant="contained" disableElevation >
          { t('participate') }
        </Button>
        <p/>
        <Button onClick={ setOpenModalSubscribe } fullWidth variant="outlined" color="primary" disableElevation >
          { t('subscribeOff') }
        </Button>
      </GroupFooter>
    </Group>
  );
};

export default SubscriptionGroup;
