import Button from '@material-ui/core/Button';
import styled from '@material-ui/core/styles/styled';

const StyledButton = styled(Button)(({ theme }) => ({
  padding: '17px 45px 15px 45px',
  fontWeight: 700,
  fontSize: 13,
  height: 48,
  borderRadius: 24,
  fontFamily: 'Tele2',
  borderColor: theme.palette.primary.main,
  maxWidth: 342,
  [theme.breakpoints.down('xs')]: {
    padding: '17px 30px 15px'
  }
}));

export default StyledButton;
