import createTheme, { Theme } from '@material-ui/core/styles/createTheme';

export type TCustomTheme = {
  colors: Record<string, string>
} & Theme;

const theme = createTheme({
  palette: {
    primary: { main: '#000000' },
    secondary: { main: '#0098DC' }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1200,
      xl: 1536
    }
  },
  typography: {
    fontFamily: '\'Roboto\', sans-serif',
    h1: {
      fontWeight: 700,
      fontSize: 36,
      textTransform: 'uppercase',
      fontFamily: 'Tele2'
    },
    h2: {
      fontWeight: 700,
      fontSize: 22,
      fontFamily: 'Tele2'
    },
    h3: {
      fontWeight: 700,
      fontSize: 22,
      fontFamily: 'Tele2'
    },
    h4: {
      fontWeight: 700,
      fontSize: 28,
      fontFamily: 'Tele2'
    },
    h5: {
      fontSize: 16,
      fontWeight: 700,
      fontFamily: 'Tele2'
    },
    body1: {
      fontSize: 15
    },
    body2: {
      fontSize: 11
    }
  }
});

theme.typography.h1[theme.breakpoints.up('md')] = {
  fontSize: 48
};
theme.typography.h2[theme.breakpoints.up('md')] = {
  fontSize: 28
};
theme.typography.h3[theme.breakpoints.up('md')] = {
  fontSize: 48,
  textTransform: 'uppercase'
};
theme.typography.h5[theme.breakpoints.up('md')] = {
  fontSize: 24
};

const customTheme: TCustomTheme = {
  ...theme,
  colors: {
    lightestGrey: '#f8f8f8',
    lightGrey: '#e8e8e8',
    grey: '#a1a1a1',
    darkGrey: '#232628'
  }
};

export default customTheme;
